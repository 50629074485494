<template>
	<div class="pageContainer">
		<a-row class="home">
			<a-spin :spinning="pageLoading">
				<a-row class="titleRow">
					<span class="longString"></span>
					检测流程
				</a-row>
				<a-steps class="steps" labelPlacement="vertical">
					<a-step title="上传素材" />
					<a-step title="付款" />
					<a-step title="检测" />
					<a-step title="查看结果" />
				</a-steps>
				<a-row class="titleRow">
					<span class="longString"></span>
					检测订单管理
				</a-row>
				<a-row class="row">
					<a-col :span="3" class="center">
						全部订单
						<span class="num">{{ allOrderCount }}</span>
					</a-col>
					<a-col :span="3" class="center">
						待付款
						<span class="num">{{ pendingPayCount }}</span>
					</a-col>
					<a-col :span="3" class="center">
						待检测
						<span class="num">{{ testedOrderCount }}</span>
					</a-col>
					<a-col :span="3" class="center">
						已完成
						<span class="num">{{ completedTradeCount }}</span>
					</a-col>
					<a-col :span="3" class="center">
						退款订单
						<span class="num">{{ refundOrderCount }}</span>
					</a-col>
					<a-col :span="3" class="center last">
						已关闭
						<span class="num">{{ closedOrderCount }}</span>
					</a-col>
				</a-row>
				<a-row class="titleRow">
					<span class="longString"></span>
					常用功能
				</a-row>
				<a-row class="imgRow">
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/advertisingCheck.png" @click="checkAdvertising()" />
					<!-- <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/personal_center.png" @click="personCenter()" /> -->
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/tfjc.png" @click="PutinMonitor()" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/flzx.png" @click="Attorney()" />
					<!-- <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/xlsp.png" @click="customerService('medical')" /> -->
					<!-- <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/Medical%20examination%20and%20approval.png" @click="undone()" /> -->
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/service.png" @click="customerService('customerService')" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/%E4%B8%93%E5%AE%B6%E4%BA%BA%E6%89%8D.png" @click="talentPool()" />
					<img class="img" src="https://resources.xbov.cn/img/industryTrainingImg.png" @click="industryTraining()" />
				</a-row>
				<a-row class="imgRow">
					<img class="img" src="https://resources.xbov.cn/img/contractImg.png" @click="checkContract()" />
					<!-- <img class="img" src="https://resources.xbov.cn/img/contractImg.png" @click="checkSecurity()" /> -->
					<img class="img" src="@/assets/img/securityImg.png" @click="checkSecurity()" />
				</a-row>
				<a-row class="titleRow">
					<span class="longString"></span>
					服务功能
				</a-row>
				<a-row class="imgRow">
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/cwfp.png" @click="financialManagement()" />
					<!-- <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/change_password.png" @click="changePass()" /> -->
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/nrcy.png" @click="contentCreation()" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/yxch.png" @click="undone()" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/flxx.png" @click="routerChange('LawStudy')" />
					<img class="img" src="@/assets/img/notice.png" @click="routerChange('Notice')" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/hybg.png" @click="undone()" />
					<!-- <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/wfal.png" @click="undone()" /> -->
				</a-row>
				<a-row class="imgRow">
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/wfal.png" @click="undone()" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/xlsp.png" @click="customerService('medical')" />
					<img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/mediaResources2.jpg" @click="mediaResources()" />
					<!-- <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/%E4%B8%93%E5%AE%B6%E4%BA%BA%E6%89%8D.png" @click="talentPool()" />
                    <img class="img" src="https://resources.xbov.cn/img/hxznggfwpt/%E8%A1%8C%E4%B8%9A%E5%9F%B9%E8%AE%AD.png" @click="industryTraining()" /> -->
				</a-row>
			</a-spin>
		</a-row>
		<CustomerService :visible="visible" :closeModal="closeModal" :goType="goType" />
		<UndoneModal :undoneModalVisible="undoneModalVisible" :closeMessageModal="closeMessageModal" />
	</div>
</template>
<script>
import CustomerService from './components/CustomerService';
import UndoneModal from '../../components/UndoneModal';
export default {
	components: {
		CustomerService,
		UndoneModal,
	},
	data() {
		return {
			visible: false,
			goType: '',
			undoneModalVisible: false,
			allOrderCount: 0,
			pendingPayCount: 0,
			testedOrderCount: 0,
			completedTradeCount: 0,
			refundOrderCount: 0,
			closedOrderCount: 0,
			pageLoading: true,
		};
	},
	created() {
		this.getTradeCount();
	},
	methods: {
		routerChange(data) {
			this.$router.push({
				name: data,
			});
		},
		getTradeCount() {
			const _this = this;
			this.$http
				.post('/monitortrade/tradeCount', {})
				.then(res => {
					_this.pageLoading = false;
					_this.allOrderCount = res.data.monitorTradePoListAll.length;
					_this.pendingPayCount = res.data.toBePaidList.length;
					_this.testedOrderCount = res.data.poToBeTestedList.length;
					_this.completedTradeCount = res.data.poCompleted.length;
					_this.refundOrderCount = res.data.poRefundOrder.length;
					_this.closedOrderCount = res.data.poClosedList.length;
				})
				.catch(err => {
					_this.pageLoading = false;
				});
		},
		closeMessageModal() {
			this.undoneModalVisible = false;
		},
		contentCreation() {
			window.open('http://h5.wenming.cn/wmreport/download.html#/1');
		},
		undone() {
			this.undoneModalVisible = true;
		},

		PutinMonitor() {
			this.$router.push({
				name: 'PutIn',
				query: {},
			});
		},

		checkAdvertising() {
			this.$router.push({
				name: 'CheckAdvertising',
				query: {},
			});
		},
		personCenter() {
			this.$router.push({
				name: 'PersonCenter',
				query: {},
			});
		},
		changePass() {
			this.$router.push({
				name: 'PersonCenter',
				query: {},
			});
		},
		financialManagement() {
			this.$router.push({
				name: 'FinancialManagement',
				query: {},
			});
		},
		mediaResources() {
			this.$router.push({
				name: 'MediaResources',
			});
		},
		customerService(type) {
			this.visible = true;
			this.goType = type;
		},
		closeModal() {
			this.visible = false;
		},
		// 专家人才库
		talentPool() {
			this.$router.push({
				name: 'TalentPool',
				query: {},
			});
		},
		// 行业培训
		industryTraining() {
			this.$router.push({
				name: 'IndustryTraining',
				query: {},
			});
		},
		// 法律咨询
		Attorney() {
			this.$router.push({
				name: 'Attorney',
				query: {},
			});
		},
		//合同检测
		checkContract() {
			this.$router.push({
				name: 'CheckContract',
				query: {},
			});
		},
		//安全检查
		checkSecurity() {
			this.$router.push({
				name: 'CheckSecurity',
				query: {},
			});
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	.home {
		background: #fff;
		height: 100%;
		overflow: auto;
		.titleRow {
			margin-top: 30px;
			font-size: 20px;
			position: relative;
			margin-left: 20px;
			.longString {
				width: 3px;
				height: 25px;
				background-color: #2d7bf8;
				position: absolute;
				top: -3px;
				left: -10px;
			}
		}
		.steps {
			width: 70%;
			margin-top: 30px;
			margin-left: 30px;
		}
		.imgRow {
			margin-top: 40px;
			margin-left: 20px;
			.img {
				width: 220px;
				height: 140px;
				margin-right: 30px;
				cursor: pointer;
			}
		}
		.imgRow:last-child {
			margin-bottom: 20px;
		}
		.row {
			margin-top: 30px;
			.center {
				text-align: center;
				font-size: 16px;
				height: 40px;
				padding-top: 30px;
				color: #999999;
				border-right: 1px solid #e4e4e4;
				position: relative;
				.num {
					font-size: 30px;
					font-weight: 700;
					width: 213px;
					color: #2d7bf8;
					position: absolute;
					top: -15px;
					left: 0;
					// left: 78px;
				}
			}
			.last {
				border: 0;
			}
		}
	}
}
</style>
