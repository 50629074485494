<template>
    <a-modal :visible="undoneModalVisible" :footer="false" @cancel="closeModal">
        <div class="imgBox flex justify-center">
            <img src="https://resources.xbov.cn/img/hxznggfwpt/undone.png" />
        </div>
        <div class="flex justify-center text">此功能正在升级中</div>
        <div class="flex justify-center text">敬请期待</div>
    </a-modal>
</template>

<script>
export default {
    props: ["undoneModalVisible", "closeMessageModal"],
    methods: {
        closeModal() {
            this.closeMessageModal();
        },
    },
};
</script>

<style lang="less" scoped>
.imgBox {
    img {
        width: 245px;
        height: 245px;
    }
}
.text {
    color: #009634;
    font-size: 26px;
}
</style>
